// Bootstrap Framework
import './scss/index.scss';
const jQuery = require('jquery');
window.$ = window.jQuery = jQuery;
import 'popper.js';
import 'bootstrap';
import 'jquery-match-height';
import "slick-carousel";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import fancybox from "@fancyapps/fancybox";
import axios from 'axios';
import swal from 'sweetalert2';
window.swal = window.Swal = swal;
//Vue
import Vue from "vue";
import "regenerator-runtime/runtime";
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

$(function () {

    //#region Pulizia Session Storage

    //#region Filtri Categorie
    var IDCategoria = $("[id*=hidCategoriaProdotto]").val();
    if (IDCategoria) {
        for (var obj in sessionStorage) {
            if (sessionStorage.hasOwnProperty(obj) && obj.includes("FilterCategory")) {
                if (!obj.includes(IDCategoria)) {
                    sessionStorage.removeItem(obj);
                }
            }
        }
    }
    else {
        for (var obj in sessionStorage) {
            if (sessionStorage.hasOwnProperty(obj) && obj.includes("FilterCategory")) {
                sessionStorage.removeItem(obj);
            }
        }
    }
    //#endregion

    //#region Filtri Brand
    var IDBrand = $("[id*=hidBrandProdotto]").val();
    if (IDBrand) {
        for (var obj in sessionStorage) {
            if (sessionStorage.hasOwnProperty(obj) && obj.includes("FilterBrand")) {
                if (!obj.includes(IDBrand)) {
                    sessionStorage.removeItem(obj);
                }
            }
        }
    }
    else {
        for (var obj in sessionStorage) {
            if (sessionStorage.hasOwnProperty(obj) && obj.includes("FilterBrand")) {
                sessionStorage.removeItem(obj);
            }
        }
    }
    //#endregion

    //#endregion

    //#region bootstrap navbar + hamburger + body scroll lock
    $('#navbarMain').on('show.bs.collapse', function (e) {
        $('.navbar-toggler .hamburger').addClass('is-active');
        let scrollableNav = document.querySelector('#navbarMain');
        disableBodyScroll(scrollableNav);
    });
    $('#navbarMain').on('hidden.bs.collapse', function (e) {
        $('.navbar-toggler .hamburger').removeClass('is-active');
        let scrollableNav = document.querySelector('#navbarMain');
        enableBodyScroll(scrollableNav);
    });
    //#endregion

    //#region Lang Switcher

    $('[id*=ddlLingue]').change(function () {
        var idx = this.selectedIndex;
        $("select#selected").prop('selectedIndex', idx);

        window.location.href = $('[id*=ddlLingue]').val();
    });

    //#endregion

    //#region home slider
    $('.slider').slick({
        fade: true,
        prevArrow: '.slider__arrow--prev',
        nextArrow: '.slider__arrow--next',
    });
    //#endregion

    //#region pagina generica slider
    $('.slider-pagebase').slick({
        fade: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000
    });
    //#endregion

    //#region carousels
    $('.carousel').each(function () {
        let nSlides = $(this).data('carousel-items');
        let $prevArrow = $(this).parent().find('.carousel__arrow--prev');
        let $nextArrow = $(this).parent().find('.carousel__arrow--next');
        $(this).slick({
            prevArrow: $prevArrow,
            nextArrow: $nextArrow,
            slidesToShow: nSlides,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: nSlides / 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    })
    //#endregion

    //#region product gallery
    $('.gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.gallery__thumbs'
    });
    $('.gallery__thumbs').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        vertical: true,
        asNavFor: '.gallery',
        focusOnSelect: true
    });
    //#endregion

    //#region category

    const Category = document.getElementById("divCategoryView");
    if (Category) {
        const CategoryView = new Vue({
            el: "#divCategoryView",
            data() {
                return {
                    basepath: $("[id*=hidBasePath]").val(),
                    IDCategoria: $("[id*=hidIDCategoria]").val(),
                    Culture: $("[id*=hidCulture]").val(),
                    SottoCategorie: [],
                    SottoCategorieLoading: true,
                    Brands: [],
                    BrandsLoading: true,
                    Products: null,
                    ProductsLoading: true,
                    SelectedBrands: [],
                    SelectedSottoCategorie: [],
                    QueryStringCategory: null,
                    PageCount: 0,
                    CurrentPage: 1,
                    FirstElement: 0,
                    LastElement: 0,
                    OrderBy: '',
                    CollapseIndex: 15
                };
            },
            async mounted() {

                //console.log("Caricamento Sottocategorie");
                this.SottoCategorie = await this.GetSottoCategorie();

                //console.log("Caricamento Brand");
                this.Brands = await this.GetBrands();

                const urlParams = new URLSearchParams(window.location.search);
                const myParam = urlParams.get('cat');
                if (myParam) {
                    this.QueryStringCategory = myParam;
                    this.SelectedSottoCategorie.push(myParam);
                    console.log('ID Query String: ' + myParam);
                }   

                //#region carico i filtri del session storage
                var LoadFilter = sessionStorage.getItem('FilterCategory-' + this.IDCategoria);
                if (LoadFilter) {
                    var StoredData = JSON.parse(LoadFilter);
                    if (StoredData) {
                        if (StoredData.Brand && StoredData.Brand.length > 0) {
                            this.SelectedBrands = StoredData.Brand;
                        }

                        if (StoredData.SubCategory && StoredData.SubCategory.length > 0) {
                            this.SelectedSottoCategorie = StoredData.SubCategory;
                        }
                    }
                }
                //#endregion


                //console.log("Caricamento Prodotti");
                this.OrderBy = $("[id*=OrderBy]").val();
                this.Products = await this.GetProducts();
            },
            methods: {
                GetSottoCategorie: function () {
                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: this.basepath + '/handlers/ProductAPI.ashx?type=categories&culture=' + this.Culture + '&IDCategoriaFather=' + this.IDCategoria
                        })
                        .then(response => {
                            //console.log(response.data);
                            resolve(response.data);
                        })
                        .catch(error => { console.log('error categories: ' + error); })
                        .finally(() => { this.SottoCategorieLoading = false;})
                    })
                },
                GetBrands: function () {
                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: this.basepath + '/handlers/ProductAPI.ashx?type=brands&culture=' + this.Culture + '&IDCategoriaFather=' + this.IDCategoria
                        })
                        .then(response => {
                            //console.log(response.data);
                            resolve(response.data);
                        })
                        .catch(error => { console.log('error brands: ' + error); })
                        .finally(() => { this.BrandsLoading = false; })
                    })

                 
                },
                GetProducts: function () {
                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: this.basepath + '/handlers/ProductAPI.ashx',
                            params: {
                                type: 'products',
                                culture: this.Culture,
                                IDCategoriaFather: this.IDCategoria,
                                brands: this.SelectedBrands.toString(),
                                sottocategorie: this.SelectedSottoCategorie.toString(),
                                currentpage: this.CurrentPage,
                                OrderBy: this.OrderBy
                            }
                        })
                        .then(response => {
                            //console.log(response.data);
                            resolve(response.data.Products);

                            this.PageCount = response.data.PageCount;
                            this.TotalElement = response.data.TotalElement;
                            this.FirstElement = ((response.data.CurrentPage - 1) * response.data.Limit) + 1;
                            this.LastElement = (this.FirstElement + (response.data.Products.length - 1));
                        })
                        .catch(error => { console.log('error products: ' + error); })
                        .finally(() => {  this.ProductsLoading = false; })
                    })
                },
                async OrderByParam(event) {
                    //console.log(event.target.value)
                    this.OrderBy = event.target.value;

                    this.CurrentPage = 1;
                    this.ProductsLoading = true;
                    this.Products = await this.GetProducts();

                    var ScroolTo = document.getElementById("listProd");
                    ScroolTo.scrollIntoView();
                },
                async BrandChecked(brand) {
                    if (brand) {
                        if (!this.SelectedBrands.includes(brand.IDCategoria)) {
                            this.SelectedBrands.push(brand.IDCategoria);
                        }
                        else {
                            this.SelectedBrands.splice(this.SelectedBrands.findIndex(el => el === brand.IDCategoria), 1);
                        }
                        //console.log(this.SelectedBrands);
                    }
                },
                async SottoCategoriaChecked(SottoCategoria) {
                    if (SottoCategoria) {
                        if (!this.SelectedSottoCategorie.includes(SottoCategoria.IDCategoria)) {
                            this.SelectedSottoCategorie.push(SottoCategoria.IDCategoria);
                        }
                        else {
                            this.SelectedSottoCategorie.splice(this.SelectedSottoCategorie.findIndex(el => el === SottoCategoria.IDCategoria), 1);
                        }
                    }
                },
                async FilterProducts() {
                    this.CurrentPage = 1;
                    this.ProductsLoading = true;
                    this.Products = await this.GetProducts();

                    //Toggle menu filtri mobile
                    $("[id*=collapseOne]").removeClass("show");
                    $("[id*=titleCollpaseBar").addClass("collapsed");
                    $("[id*=titleCollpaseBar").removeAttr("aria-expanded");
                    $("[id*=titleCollpaseBar").attr("aria-expanded", "false");


                    //#region Salvo i filtri nel session storage
                    var FilterCategoryData = {
                        Category: this.IDCategoria,
                        SubCategory: this.SelectedSottoCategorie,
                        Brand: this.SelectedBrands,
                        Date: new Date()
                    };
                    sessionStorage.setItem('FilterCategory-' + this.IDCategoria, JSON.stringify(FilterCategoryData));
                    //#endregion

                    var ScroolTo = document.getElementById("listProd");
                    ScroolTo.scrollIntoView();
                },
                async EmtpyFilter() {
                    this.CurrentPage = 1;
                    this.ProductsLoading = true;
                    this.SelectedBrands = [];
                    this.SelectedSottoCategorie = [];
                    this.Products = await this.GetProducts();

                    //#region pulizia session storage
                    sessionStorage.removeItem('FilterCategory-' + this.IDCategoria);
                    //#endregion

                    var ScroolTo = document.getElementById("listProd");
                    ScroolTo.scrollIntoView();
                },
                async clickCallback(pageNum) {
                    this.CurrentPage = pageNum;
                    this.ProductsLoading = true;
                    this.Products = await this.GetProducts();
                   
                    var ScroolTo = document.getElementById("listProd");
                    ScroolTo.scrollIntoView();
                }
            }
        })
    }

    //#endregion

    //#region brand

    const Brand = document.getElementById("divBrandView");
    if (Brand) {
        const BrandView = new Vue({
            el: "#divBrandView",
           data() {
                return {
                    basepath: $("[id*=hidBasePath]").val(),
                    IDBrand: $("[id*=hidIDBrand]").val(),
                    Culture: $("[id*=hidCulture]").val(),
                    SottoCategorie: [],
                    SottoCategorieLoading: false,
                    Brands: [],
                    BrandsLoading: true,
                    Products: null,
                    ProductsLoading: true,
                    SelectedSottoCategorie: [],
                    QueryStringCategory: null,
                    TotalElement: 0,
                    PageCount: 0,
                    CurrentPage: 1,
                    FirstElement: 0,
                    LastElement: 0,
                    OrderBy: '',
                    CollapseIndex: 15
                };
            },
            async mounted() {
                //console.log("Caricamento Sottocategorie");
                this.SottoCategorie = await this.GetSottoCategorie();

                //#region carico i filtri del session storage
                var LoadFilter = sessionStorage.getItem('FilterBrand-' + this.IDBrand);
                if (LoadFilter) {
                    var StoredData = JSON.parse(LoadFilter);
                    if (StoredData) {
                        if (StoredData.SubCategory && StoredData.SubCategory.length > 0) {
                            this.SelectedSottoCategorie = StoredData.SubCategory;
                        }
                    }
                }
                //#endregion

                //console.log("Caricamento Prodotti");
                this.OrderBy = $("[id*=OrderBy]").val();
                this.Products = await this.GetProducts();

            },
            methods: {
                GetSottoCategorie: function () {
                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: this.basepath + '/handlers/ProductAPI.ashx?type=categories-brand&culture=' + this.Culture + '&IDBrand=' + this.IDBrand
                        })
                        .then(response => {
                            //console.log(response.data);
                            resolve(response.data);
                        })
                        .catch(error => { console.log(error); })
                        .finally(() => { this.SottoCategorieLoading = false; })
                    })
                },
                GetProducts: function () {
                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: this.basepath + '/handlers/ProductAPI.ashx',
                            params: {
                                type: 'products-brand',
                                culture: this.Culture,
                                IDBrand: this.IDBrand,
                                sottocategorie: this.SelectedSottoCategorie.toString(),
                                currentpage: this.CurrentPage,
                                OrderBy: this.OrderBy
                            }
                        })
                        .then(response => {
                            //console.log(response.data);
                            resolve(response.data.Products);

                            this.PageCount = response.data.PageCount;
                            this.TotalElement = response.data.TotalElement;
                            this.FirstElement = ((response.data.CurrentPage - 1) * response.data.Limit) + 1;
                            this.LastElement = (this.FirstElement + (response.data.Products.length - 1));
                        })
                        .catch(error => { console.log('error products: ' + error); })
                        .finally(() => this.ProductsLoading = false)
                    })
                },
                async OrderByParam(event) {
                    //console.log(event.target.value)
                    this.OrderBy = event.target.value;

                    this.CurrentPage = 1;
                    this.ProductsLoading = true;
                    this.Products = await this.GetProducts();

                    var ScroolTo = document.getElementById("listProd");
                    ScroolTo.scrollIntoView();
                },
                async SottoCategoriaChecked(SottoCategoria) {
                    if (SottoCategoria) {
                        if (!this.SelectedSottoCategorie.includes(SottoCategoria.IDCategoria)) {
                            this.SelectedSottoCategorie.push(SottoCategoria.IDCategoria);
                        }
                        else {
                            this.SelectedSottoCategorie.splice(this.SelectedSottoCategorie.findIndex(el => el === SottoCategoria.IDCategoria), 1);
                        }
                        //console.log(this.SelectedSottoCategorie);
                    }
                },
                async FilterProducts() {
                    this.CurrentPage = 1;
                    this.ProductsLoading = true;
                    this.Products = await this.GetProducts();

                    //Toggle menu filtri mobile
                    $("[id*=collapseOne]").removeClass("show");
                    $("[id*=titleCollpaseBar").addClass("collapsed");
                    $("[id*=titleCollpaseBar").removeAttr("aria-expanded");
                    $("[id*=titleCollpaseBar").attr("aria-expanded", "false");

                    //#region Salvo i filtri nel session storage
                    var FilterCategoryData = {
                        Brand: this.IDBrand,
                        SubCategory: this.SelectedSottoCategorie,
                        Date: new Date()
                    };

                    sessionStorage.setItem('FilterBrand-' + this.IDBrand, JSON.stringify(FilterCategoryData));
                    //#endregion


                    var ScroolTo = document.getElementById("listProd");
                    ScroolTo.scrollIntoView();
                },
                async EmtpyFilter() {
                    this.CurrentPage = 1;
                    this.ProductsLoading = true;
                    this.SelectedSottoCategorie = [];
                    this.Products = await this.GetProducts();

                    //#region pulizia session storage
                    sessionStorage.removeItem('FilterBrand-' + this.IDBrand);
                    //#endregion

                    var ScroolTo = document.getElementById("listProd");
                    ScroolTo.scrollIntoView();
                },
                async clickCallback(pageNum) {
                    this.CurrentPage = pageNum;
                    this.ProductsLoading = true;
                    this.Products = await this.GetProducts();

                    var ScroolTo = document.getElementById("listProd");
                    ScroolTo.scrollIntoView();
                }
            }
        })
    }

    //#endregion

});